












import { Vue, Component, Prop } from 'vue-property-decorator';
import { AlertEntry, AlertFields } from '~/types/Alert';
import GhLink from '~/patterns/atoms/link/link.vue';
import RichTextRenderer from '~/patterns/molecules/rich-text/rich-text.vue';

// predefined background colors
export const RED = '#FF6363';
export const BLUE = '#006BA6';
export const GREEN = '#66B981';

@Component({
    components: { RichTextRenderer, GhLink }
})
export default class Alert extends Vue {
    @Prop() readonly alert: AlertEntry;

    get alertObject(): AlertFields | null {
        if (this.alert) {
            return this.alert.fields;
        }
        return null;
    }

    get alertColor(): string | undefined {
        if (this.alert && this.alert.fields && this.alert.fields.color) {
            const regExp = /\(([^)]+)\)/;
            const matches = regExp.exec(this.alert.fields.color);

            if (matches) {
                return matches.pop();
            }
        }
        return undefined;
    }

    get alertBackgroundColor(): string {
        if (this.alertColor) {
            switch (this.alertColor) {
                case RED:
                    return 'alert--red';
                case BLUE:
                    return 'alert--blue';
                case GREEN:
                    return 'alert--green';
                default:
                    return 'alert--red';
            }
        }
        return 'alert--red';
    }
}
