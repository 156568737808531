




import { Component, Vue, Watch } from 'vue-property-decorator';
import { Entry } from 'contentful';
import { mapGetters } from 'vuex';
import { Context } from '@nuxt/types';

import ContentfulPage from '~/api/contentful/page';

import { getImage } from '~/helpers/image';
import { setMetadataFromEntry } from '~/helpers/seo';
import { setMunicipalityCookie, setMunicipalityState } from '~/helpers/municipalityState';

import { PageType } from '~/types/Page';
import { Site } from '~/types/RelEnvironment';
import { MunicipalityBannerEntry } from '~/types/MunicipalityBanner';
import { MunicipalityFields, MunicipalityType } from '~/types/Municipality';
import { HasImage } from '~/interfaces/image';
import { ProductSolutionOpportunityEntry, ProductSolutionEntry } from '~/types/Product';
import { cookieName } from '~/store/municipality';

import PageLayout from '~/patterns/organisms/page-layout/page-layout.vue';

import Alerts from '~/patterns/molecules/alerts.vue';
import CloudinaryImage from '~/patterns/atoms/cloudinary-image/cloudinary-image.vue';
import ContentBlocks from '~/patterns/organisms/_collections/content-blocks/content-blocks.vue';
import FooterPhoto from '~/patterns/molecules/footer-photo/footer-photo.vue';
import GhHero from '~/patterns/organisms/gh-hero/gh-hero.vue';
import Note from '~/patterns/atoms/note/note.vue';
import RichTextRenderer from '~/patterns/molecules/rich-text/rich-text.vue';

const EditButton = () => import(/* webpackPrefetch: true */ '~/patterns/molecules/edit-button.vue');

const checkEntryForMunicipality = (entry: PageType): boolean => {
    const entryFields = entry && entry.fields ? entry.fields : null;

    return (
        !!entryFields &&
        !!entryFields.municipality &&
        !!entryFields.municipality.fields &&
        !!entryFields.municipality.fields.municipality
    );
};

// The @Component decorator indicates the class is a Vue component
@Component({
    components: {
        Alerts,
        CloudinaryImage,
        ContentBlocks,
        EditButton,
        FooterPhoto,
        GhHero,
        Note,
        PageLayout,
        RichTextRenderer
    },

    computed: {
        ...mapGetters({ site: 'site/site' })
    },

    async asyncData(this: Page, context: Context): Promise<void | { entry: PageType }> {
        const { params, error, payload, store } = context;

        if (!params.slug) {
            error({ statusCode: 404, message: 'Pagina niet gevonden' });
        }

        if (payload) {
            return { entry: payload as PageType };
        }

        const contentfulPage = new ContentfulPage();
        const site: Site = store.getters['site/site'];
        const zakelijk: boolean = store.getters['site/zakelijk'];
        const zipcode: string = store.getters['location/zipcode'];

        try {
            // Get all municipality pages so we can compare slug param with municipality URLs
            await store.dispatch('municipality/all');

            if (store.getters['municipality/all'][params.slug]) {
                params.municipality = params.slug;
            }

            setMunicipalityCookie(context);

            if (
                zakelijk &&
                params.municipality &&
                params.slug === 'partijen' &&
                store.getters['municipality/fields'] &&
                store.getters['municipality/fields'].relDomain &&
                store.getters['municipality/fields'].relDomain.includes('zakelijk')
            ) {
                params.slug += '-' + params.municipality;
            }

            const entries = await contentfulPage.getBySlug(params.slug, site);

            if (entries.items.length === 0) {
                // Fallback content for Zakelijk Loket or VvE
                if (site !== 'particulier' && (store.getters['municipality/all'][params.slug] || zipcode)) {
                    const fallback = await contentfulPage.getBySlug(`${site}-loket`, site);

                    const location = store.getters['location/location'];
                    if (location && location.municipality) {
                        store.dispatch('municipality/loadByApiId', location.municipality.id).then(() => {
                            store.dispatch('municipality/setSlug', params.slug);
                        });
                    }

                    if (fallback.items[0]) {
                        return { entry: fallback.items[0] };
                    }
                }
                return error({ statusCode: 404, message: 'Pagina niet gevonden' });
            }
            return { entry: entries.items[0] };
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            return error({ statusCode: 500, message: 'Er ging iets mis' });
        }
    },

    head(this: Page): object {
        // @ts-ignore
        const municipality = this.$cookies.get(cookieName);
        return setMetadataFromEntry(this.entry as PageType, this.$route, municipality);
    }
})
class Page extends Vue {
    entry: PageType | null = null;

    opportunities?: ProductSolutionOpportunityEntry[] | null = null;

    solutions?: ProductSolutionEntry[] | null = null;

    site: string;

    snippetJson: boolean = false;

    loaded: boolean = false;

    get cookie(): string {
        // @ts-ignore
        return this.$cookies.get(cookieName);
    }

    get fields(): MunicipalityFields | undefined {
        if (this.isMunicipalityPage) {
            return this.$store.getters['municipality/fields'];
        }
        return undefined;
    }

    get pageType(): string {
        if (this.entry && (checkEntryForMunicipality(this.entry) || this.entry.fields.slug === 'zakelijk-loket')) {
            return 'page-municipality';
        }
        return 'page';
    }

    get showNotice(): boolean {
        if (this.$store.getters['site/zakelijk']) {
            return this.entry?.fields?.slug === 'zakelijk-loket';
        }

        if (this.$store.getters['site/vve']) {
            return !!this.fields && (!this.fields.relDomain || !this.fields.relDomain.includes('vve'));
        }

        return !!this.fields && (!this.fields.relDomain || !this.fields.relDomain.includes('particulier'));
    }

    get isMunicipalityPage(): boolean {
        return !!this.entry && checkEntryForMunicipality(this.entry);
    }

    get hero(): MunicipalityBannerEntry {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (this.entry as Entry<any>).fields.hero;
    }

    get municipalityId(): string {
        if (this.isMunicipalityPage) {
            return this.$store.state.municipality.municipality?.sys.id;
        }
        return '';
    }

    get municipality(): MunicipalityType {
        return this.$store.getters['municipality/municipality'];
    }

    get footerImage(): HasImage | undefined {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const entry = this.entry as Entry<any>;
        return getImage(entry.fields, 'footerImage', 'footerCloudinaryImage');
    }

    @Watch('$route.params', { immediate: true })
    async routeChanged(params) {
        if (!params.municipality) {
            await this.$store.dispatch('municipality/all');
            if (this.$store.getters['municipality/all'][params.slug]) {
                // @ts-ignore
                this.$cookies.set(cookieName, params.slug, { path: '/', maxAge: 30 * 24 * 60 * 60 });
                await setMunicipalityState(this.$store, params.slug);
            }
        }

        if (process.client) {
            await setMunicipalityState(this.$store, this.cookie);
        }

        if (this.$route.fullPath === '/' && !!this.cookie) {
            this.$router.replace('/' + this.cookie + '/');
        }
    }

    beforeMount() {
        if (this.isMunicipalityPage) {
            // Do not move to head() because it will overwrite all other classes on the body
            document.body.classList.add('municipality-page');
        }
    }

    created() {
        this.$nextTick(() => (this.loaded = true));
    }
}

export default Page;
