import { Store } from 'vuex';
import { Context } from '@nuxt/types';
import { cookieName } from '~/store/municipality';

/**
 * Set municipality cookie if different from existing
 *
 * @param {Context} ctx Nuxt context object
 */
export function setMunicipalityCookie(ctx: Context): void {
    const { params, app } = ctx;

    if (params.municipality && params.municipality !== app.$cookies.get(cookieName)) {
        app.$cookies.set(cookieName, params.municipality, { path: '/', maxAge: 30 * 24 * 60 * 60 });
    }
    if (!params.municipality) {
        app.$cookies.remove(cookieName, { path: '/', maxAge: 0 });
    }
}

/**
 * Set municipality based on given slug
 *
 * @param  {Store<any>}    store      Vuex store
 * @param  {string}        cookieSlug current municipality slug
 * @return {Promise<void>}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function setMunicipalityState(store: Store<any>, cookieSlug: string | undefined): Promise<void> {
    const storeSlug = store.getters['municipality/municipalitySlug'];

    if (!cookieSlug) {
        // Clear all if no municipality
        return store.dispatch('municipality/clear');
    }

    if (!!cookieSlug && storeSlug !== cookieSlug) {
        try {
            // Get all municipality slugs so we can compare slug param with municipality URLs
            await store.dispatch('municipality/all');

            const id = store.getters['municipality/all'][cookieSlug];

            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            return store.dispatch('municipality/loadByApiId', id).then(() => {
                store.dispatch('municipality/setSlug', cookieSlug);

                const relDomain = store.getters['municipality/fields'].relDomain || [];
                const site = store.getters['site/site'];
                const showSecondaryLinks = relDomain.filter(item => item !== site).length;
                store.dispatch('navigation/setShowSecondaryLinks', showSecondaryLinks);
            });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    }
}
