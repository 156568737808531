import { Route } from 'vue-router';
import { MetaInfo } from 'vue-meta';
import { ArticleType } from '~/types/Article';
import { PageType } from '~/types/Page';
import { ProductSolutionEntry } from '~/types/Product';
import { InDeBuurtEntry } from '~/types/InDeBuurt';
import { generateSlugForEntry } from '~/helpers/routes';
import config from '~/config/config';
import { ExperienceEntry } from '~/types/Experience';
import { ExperiencesOverviewType } from '~/types/ExperiencesOverview';
import { FinancialBenefitOverviewEntry } from '~/types/FinancialBenefitOverview';
import { HasSEOMetadata } from '~/interfaces/seoMetadata';
import { Entry } from '~/node_modules/contentful';
import production from '~/config/production';

/* eslint-disable dot-notation */
export function setMetadataFromEntry(
    // prettier-ignore
    entry: PageType | ArticleType | ProductSolutionEntry | InDeBuurtEntry | ExperienceEntry | ExperiencesOverviewType | Entry<HasSEOMetadata> | FinancialBenefitOverviewEntry,
    route: Route,
    municipality: string | undefined
): MetaInfo {
    const { fields } = entry;

    const path = municipality ? route.path.replace(`/${municipality}`, '') : route.path;
    const metaInfo: MetaInfo = {
        meta: [],
        link: [
            {
                rel: 'canonical',
                href: `https://${production.domain}${path}`
            }
        ]
    };

    const description = config.domain.includes('zakelijk')
        ? 'Het zakelijk loket helpt u bij het verduurzamen van uw bedrijfspand. U vindt hier interessante energiebesparende maatregelen voor uw sector en informatie over subsidies, financieringsmogelijkheden en wet- en regelgeving. '
        : 'Interesse in duurzaam wonen? Bij het RegionaalEnergieloket ben je aan het juiste adres. Lees meer informatie over duurzaam wonen bij jou in de buurt.';

    // Use seoMetadata if defined
    if (fields.seoMetadata) {
        const seoMeta = fields.seoMetadata;
        if (seoMeta.fields.title) {
            metaInfo.title = seoMeta.fields.title;

            if (metaInfo.meta !== undefined) {
                if (seoMeta.fields.description) {
                    metaInfo.meta.push({
                        hid: 'description',
                        name: 'description',
                        content: seoMeta.fields.description
                    });
                    metaInfo.meta.push({
                        hid: 'og:description',
                        property: 'og:description',
                        content: seoMeta.fields.description
                    });
                }

                if (seoMeta.fields.keywords) {
                    metaInfo.meta.push({
                        hid: 'keywords',
                        name: 'keywords',
                        content: seoMeta.fields.keywords.join(',')
                    });
                }

                if (seoMeta.fields.ogType) {
                    metaInfo.meta.push({ hid: 'og:type', property: 'og:type', content: seoMeta.fields.ogType });
                }

                if (seoMeta.fields.ogImage && seoMeta.fields.ogImage.length > 0) {
                    // Cloudinary image objects contain snake_cased properties..
                    // eslint-disable-next-line dot-notation
                    const image = seoMeta.fields.ogImage[0]['public_id'];
                    metaInfo.meta.push({
                        hid: 'og:image',
                        property: 'og:image',
                        content: `//media.regionaalenergieloket.nl/image/upload/w_600/${image}`
                    });
                }
            }
        }
    } else if (fields['title']) {
        metaInfo.title = fields['title'];
    } else if (fields['name']) {
        metaInfo.title = fields['name'];
    } else if (fields['companyName']) {
        metaInfo.title = fields['companyName'];
    }

    if (metaInfo.meta !== undefined) {
        // og:title is always the same as the title
        if (metaInfo.title) {
            metaInfo.meta.push({ hid: 'og:title', property: 'og:title', content: metaInfo.title });
        }

        // og:url is always the generated URL
        metaInfo.meta.push({
            hid: 'og:url',
            property: 'og:url',
            content: `https://${config.domain}/${generateSlugForEntry(entry)}`
        });

        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        if (!metaInfo.meta.find(item => item.name === 'description')) {
            metaInfo.meta.push({
                hid: 'description',
                name: 'description',
                content: description
            });
        }
    }

    return metaInfo;
}
