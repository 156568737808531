





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { PageProperties } from '~/types/Page';
import { AlertEntry } from '~/types/Alert';

import Alert from '~/patterns/atoms/alert/alert.vue';

@Component({
    components: { Alert }
})
export default class Alerts extends Vue {
    alertFetchState: string | null = null;
    hasStaleData: boolean = false;
    @Prop(String) readonly pageId!: string;

    @Watch('pageProperties', { immediate: true, deep: true })
    async pagePropsChanged() {
        // Only load new alerts if there isn't a call running already.
        if (this.alertFetchState !== 'pending') {
            this.alertFetchState = 'pending';
            await this.loadAlertsFromStore();
            this.alertFetchState = 'finished';
        } else {
            this.hasStaleData = true; // Track that we have to update the alerts when the running loader if finifhsed
        }
    }

    // Check if the alerts are stale and we need to update if the state changes to finished
    @Watch('alertFetchState', { immediate: false })
    async updateStaleAlerts(val: string | null) {
        if (val === 'finished' && this.hasStaleData) {
            await this.loadAlertsFromStore();
            this.hasStaleData = false;
        }
    }

    get alerts(): AlertEntry[] | null {
        return this.$store.getters['alert/alerts'];
    }

    get pageProperties(): PageProperties | object {
        return {
            pageId: this.pageId,
            municipalityId: this.municipalityId,
            zipcode: this.zipcode
        };
    }

    get municipalityId(): string | undefined {
        const municipality = this.$store.getters['municipality/municipality'];

        if (municipality && municipality.sys && municipality.sys.id) {
            return municipality.sys.id;
        }
        return undefined;
    }

    get zipcode(): string | undefined {
        const location = this.$store.getters['location/location'];

        if (location && location.postcode) {
            return location.postcode;
        }
        return undefined;
    }

    async loadAlertsFromStore() {
        if (this.pageProperties) {
            return await this.$store.dispatch('alert/loadalerts', this.pageProperties);
        }
        return null;
    }

    beforeDestroy() {
        return this.$store.dispatch('alert/clear');
    }
}
