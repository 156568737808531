







import { Vue, Prop, Component } from 'vue-property-decorator';

@Component
export default class Note extends Vue {
    @Prop() readonly title: string;
    @Prop() readonly message: string;
}
